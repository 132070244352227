html,
body {
  max-width: 800px;
  padding: 0px 0.75em;
  margin: 0px auto;
  font-size: 100%;
  letter-spacing: 0.2px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  font-weight: bold;
}
